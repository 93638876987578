import { cleanMetaString } from '@/utils/cleanMeta';

export function useOpenGraph(pageTitle, pageDescription, pageImage, pageUrl) {
    const meta = [
        {
            property: 'og:title',
            content: cleanMetaString(pageTitle),
        },
        {
            property: 'og:description',
            content: cleanMetaString(pageDescription),
        },
        {
            property: 'og:url',
            content: pageUrl,
        },
        {
            property: 'og:type',
            content: 'website',
        },
    ];
    if (pageImage) {
        meta.push({
            property: 'og:image',
            content: pageImage,
        });
    }
    useHead({
        meta,
    });
}

export function getOgTitle(page) {
    const pageObj = toRef(page);
    let title;
    if (
        pageObj.value?.meta?.seo_title &&
        pageObj.value?.meta?.seo_title !== ''
    ) {
        title = pageObj.value?.meta?.seo_title;
    } else if (pageObj.value) {
        title = `${pageObj.value?.title} | LUMA Delikatessen`;
    } else title = '404 - ' + t('error.404.title');
    return title;
}

export function getOgDescription(page) {
    let description;
    if (
        page?.value?.meta?.search_description &&
        page?.value?.meta?.search_description !== ''
    ) {
        description = page?.value?.meta?.search_description;
    } else if (page?.value.product?.description) {
        description = page?.value.product?.description;
    } else description = '';
    return description;
}
